
import moment from 'moment';
import Select from 'react-select';
import ImportCsv from '../ImportCsv';
import { Button } from 'primereact/button';
import Currency from '../../shared/Currency';
import AsyncSelect from 'react-select/async';
import { UncontrolledCollapse } from 'reactstrap';
import React, { Component, useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import {
  getSuggestedProductsICA,
  getStockInvPart,
  getSuggestedProductsAP,
  CreateEngprojectLine,
  updateProjectOnSAP,
} from '../../../store/directusSdk';
import { generateOptionsSeries } from '../eng_projects_components/functions'
import { toast } from 'react-toastify';

export default function AddMaterialBath({ project, showListMain, whsICA, whsAP, loading2, showListSec, LoadProducts, whs, table, AddLine, update }) {

  const [serie, setSerie] = useState();
  const [product, setProduct] = useState();
  const [cant, setCant] = useState(1);
  const [warehouse, setWarehouse] = useState();
  const [priority, setPriority] = useState({ label: 'P1', value: 'P1' })
  const [loading, setLoading] = useState();


  const options = [
    { label: 'P1', value: 'P1' },
    { label: 'P2', value: 'P2' },
    { label: 'P3', value: 'P3' },
    { label: 'P4', value: 'P4' },
    { label: 'S/P', value: 'S/P' },
  ];

  const showBodegas = (OnHandByWhs) => {
    let warehouses = '';
    for (const property in OnHandByWhs) {
      warehouses += `${property}: ${OnHandByWhs[property]} un.  `;
    }
    return warehouses;
  };

  const renderSeries = (project) => {
    const series = generateOptionsSeries(project)
    if (!series) return 'No hay series disponibles';

    return <Select
      value={serie}
      onChange={(warehouse) => setSerie(warehouse)}
      options={series}
    />
  }

  const showInvPart = (product, table, ruc) => {
    if (!product) {
      return setProduct(null);
    }

    return getStockInvPart(product.code, table, ruc)
      .then(({ message }) => {
        product.stockExternal = message;
        setProduct(product);
        return;
      })
      .catch(({ code }) => {
        if (code == 203) {
          product.stockExternal = 'Inventario Particular: No Encontrado.';
          setProduct(product);
          return;
        }
      });
  };

  const AddProduct = (product, cant, warehouse, priority, serie) => {

    if (serie.value === 'ALL') {
      const projectIds = project.projects.filter((p) => p.status !== 'finished').map((e) => e.id);

      return Promise.all(projectIds.map((projectId) => CreateEngprojectLine(table === 'ICA' ? 'engprojectlinesica' : 'engprojectlinesap', {
        product_id: product.id,
        warehouse_id: warehouse.value,
        cant_pedido: cant,
        engproject_id: projectId,
        priority,
      }))).then((prods) => {
        prods.forEach((prod) => {
          const pry = projectIds.find((e) => e === prod.engproject_id);
          if (pry) {
            const producto = { ...product, id: prod.id, order: cant, movements: [], warehouse_id: warehouse, priority };

            if (product.status === 'A' && warehouse.code !== '-1') updateProjectOnSAP(pry);
            setProduct(null);
            setCant(1)
            AddLine(table, producto, pry)
          }
        });

        update();
        toast.success('¡Productos agregados con Exito!');
      }).catch((error) => toast.error('¡Error!, ' + error.message))
        .finally(() => setLoading(false));
    }
    const projects = project.projects.filter((project) => project.status !== 'finished' && project.mainProject.code.startsWith(serie.value)).map((e) => e.id);


    Promise.all([
      projects.map((projectFilter) =>
        CreateEngprojectLine(table === 'ICA' ? 'engprojectlinesica' : 'engprojectlinesap', {
          product_id: product.id,
          warehouse_id: warehouse.value,
          cant_pedido: cant,
          engproject_id: projectFilter,
          priority,
        }))
    ])
      .then((prods) => {
        prods.forEach((prod) => {
          if (product.status === 'A' && warehouse.code !== '-1') {

            projects.map((projectId) => updateProjectOnSAP(projectId));

          }
          const producto = { ...product, id: prod.id, order: cant, movements: [], warehouse_id: warehouse, priority };
          setProduct(null);
          setCant(1)

          projects.map((projectId) => {
            AddLine(table, producto, projectId)
          });
          update();
          toast.success('¡Productos agregados con Exito!');
        });
      }).catch((error) => toast.error('¡Error!, ' + error.message))
      .finally(() => setLoading(false));
  };



  const promiseOptions = (table, inputValue) => (table === 'ICA' ? getSuggestedProductsICA(inputValue) : getSuggestedProductsAP(inputValue));

  const header = (
    <React.Fragment>

      <div className="row mt-2">
        <div className="col-md-2">
          {renderSeries(project)}
        </div>
        <AsyncSelect
          cacheOptions
          loadOptions={(e) => promiseOptions(table, e)}
          defaultOptions
          isClearable
          noOptionsMessage={() => 'No se Encontró ningun producto.'}
          loadingMessage={() => 'Buscando...'}
          placeholder="Buscar producto..."
          onChange={(producto) => showInvPart(producto, table, project.ruc)}
          value={product}
          className="col-md-4"
        />
        <div className="col-md-2">
          <InputNumber
            min={1}
            value={cant}
            onValueChange={(c) => setCant(c.value)}
            showButtons
            buttonLayout="horizontal"
            decrementButtonClassName="p-button-secondary"
            incrementButtonClassName="p-button-info"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            size={1}
          />
        </div>
        <div className="col-md-2">
          <Select
            value={warehouse}
            onChange={(warehouse) => setWarehouse(warehouse)}
            options={project.company.code === 'ICA' ? whsICA : whsAP}
          />
        </div>
        <div className="col-md-1">
          <Select
            value={priority}
            onChange={(priority) => setPriority(priority)}
            options={options}
          />
        </div>
        <Button
          outline
          color="success"
          title="Agregar"
          className="col-md-1"
          onClick={() => AddProduct(product, cant, warehouse, priority.value, serie)}
          disabled={loading || cant < 1 || !product || !warehouse || !priority || !serie}
        >
          <i className={`mr-2 fas ${loading ? 'fa-cog fa-spin' : 'fa-plus-circle'} fa-lg`} />
          Add
        </Button>
      </div>
      <div className="p-grid  mt-3">
        <div className="p-col-9">
          <div className="p-d-flex p-ai-center">

            {product && (
              <>
                <span className="p-mr-3" style={{ fontSize: 14 }}>
                  Precio: <Currency value={product ? product.price : 0} />
                </span>
                <span className="p-mr-3" style={{ fontSize: 14 }}>
                  {showBodegas(product.OnHandByWhs)}
                </span>
                <span className="p-mr-3" style={{ fontSize: 14 }}>
                  {product.stockExternal}
                </span>
              </>
            )}
          </div>
        </div>

      </div>
    </React.Fragment>
  );

  return (
    <div className="container-fluid pt-3">
      {project && header}
      {project && showListMain && (
        <React.Fragment>
          {project.company.code === 'ICA' ? (
            project.error_update_ica && <div className="alert alert-danger">{project.error_update_ica}</div>
          ) :
            (
              project.error_update_ap && <div className="alert alert-danger">{project.error_update_ap}</div>
            )
          }
          <br />
        </React.Fragment>
      )}
      {project && (
        <React.Fragment>
          {!['finished', 'C'].includes(project.status) && (
            <React.Fragment>
              <Button color="secondary" id="toggler2" style={{ marginBottom: '1rem' }}> Importar .CSV</Button>
              <UncontrolledCollapse toggler="#toggler2">
                <div className="mb-2">
                  <ImportCsv
                    LoadProducts={(company, prods, errors) => {
                      LoadProducts(
                        company,
                        prods,
                        errors,
                        serie,
                        generateOptionsSeries(project)
                      )
                    }}
                    whs={project.company.code !== 'ICA' ? whsICA : whsAP}
                    company={project.mainProject.company_id.code}
                    loading={loading2}
                  />
                </div>
              </UncontrolledCollapse>
            </React.Fragment>
          )}
          {project.company.code !== 'ICA' ? (
            project.error_update_ica && <div className="alert alert-danger">{project.error_update_ica}</div>
          ) :
            (
              project.error_update_ap && <div className="alert alert-danger">{project.error_update_ap}</div>
            )
          }
          <div className="row">
            <h5 className="col-md-5">Listado de Materiales</h5>
            <p className="offset-md-3 col-md-4 text-right">
              Ultima Actualización:
              {
                project.company.code !== 'ICA' ? (
                  project.updated_sap_ica ?
                    moment(project.updated_sap_ica).format('DD-MM-YY hh:mm A')
                    :
                    '  No Actualizado'
                ) :
                  (
                    project.updated_sap_ap ?
                      moment(project.updated_sap_ap).format('DD-MM-YY hh:mm A')
                      :
                      '  No Actualizado'
                  )
              }
            </p>
          </div>

          <br />
        </React.Fragment>
      )}


    </div>
  )

}