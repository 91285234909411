import React from 'react';
import { Badge, Progress } from 'reactstrap';

export const statusBadge = (status) => {
    switch (status) {
        case 'new':
            return <Badge color="info">Nuevo</Badge>
        case 'A':
            return <Badge color="success">En Proceso</Badge>
        case 'P':
            return <Badge color="warning">Pausado</Badge>
        case 'C':
            return <Badge color="danger">Cancelado</Badge>
        default:
            return <Badge color="secondary">Finalizado</Badge>
    }
}

export const statusProgress = (status, solicitado, prueba, entrega) => {
    switch (true) {
        case (status === 'new'):
            return <Progress color="secondary" value="25">En Diseño</Progress>
        case (status === 'finished'):
            return <Progress color="dark" value="100">Finalizado</Progress>
        case (entrega && status === 'A'):
            return <Progress color="success" value="90">Por Entregar</Progress>
        case (prueba && status === 'A'):
            return <Progress color="warning" value="80">En Pruebas</Progress>
        case (solicitado && status === 'A'):
            return <Progress color="primary" value="65">En Ensamblaje</Progress>
        case (status === 'A'):
            return <Progress color="info" value="45">Aprovisionamiento</Progress>
        case (status === 'P'):
            return <Progress color="danger" value="100">Pausado</Progress>
        case (status === 'C'):
            return <Progress color="danger" value="100">Cancelado</Progress>
        default:
            return '';
    }
}

export const statusProgressLavel = (status, solicitado, prueba, entrega) => {
    switch (true) {
        case (status === 'new'):
            return 'En Diseño';
        case (status === 'finished'):
            return 'Finalizado';
        case (entrega && status === 'A'):
            return 'Por Entregar';
        case (prueba && status === 'A'):
            return 'En Pruebas';
        case (solicitado && status === 'A'):
            return 'En Ensamblaje';
        case (status === 'A'):
            return 'Aprovisionamiento';
        case (status === 'P'):
            return 'Pausado';
        case (status === 'C'):
            return 'Cancelado';
        default:
            return '';
    }
}