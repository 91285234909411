// react
import React, { Component } from "react";
// third-party
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { Button, ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import { toast } from "react-toastify";
import moment from "moment";
import PageHeader from "../shared/PageHeader";
import {
    getCompanys,
    getSuggestedEngprojects,
    getSuggestedEncargados,
    getWarehouses,
    CreateEngproject,
    getProjectByCode,
    UpdateItems,
} from "../../store/directusSdk";
import ProductsTable from "./ProductsTable";

export default class NewEngproject extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projectMain: null,
            projectSecondary: null,
            encargado: null,
            productsICA: [],
            productsAP: [],
            whsICA: [],
            whsAP: [],
            loading: false,
            errors: null,
            company: null,
            companys: [],
            projects: [],
            isOpen: false,
        };
    }

    componentDidMount() {
        getCompanys().then((companys) => this.setState({ companys }));
        getWarehouses().then(({ whsICA, whsAP }) => this.setState({ whsICA, whsAP }));
    }

    getProjectsPending = (company) => {
        return getSuggestedEngprojects(company.value).then((projects) =>
            this.setState({ company, projects, projectMain: null, projectSecondary: null, productsICA: [], productsAP: [] })
        );
    };

    promiseOptions2 = (inputValue) => getSuggestedEncargados(inputValue);

    checkErrors = () => {
        const { projectMain, encargado } = this.state;
        const errors = {};
        let error = false;
        if (!projectMain) {
            errors.projectMain = "Debe seleccionar un proyecto principal.";
            error = true;
        }
        if (projectMain && !projectMain.orden_venta) {
            errors.orden_venta = "Debe crear la orden de compra en SAP para este proyecto.";
            error = true;
        }
        if (!encargado) {
            errors.encargado = "Introduzca un Ingeniero encargado";
            error = true;
        }

        this.setState({ errors });
        return error;
    };

    save = (action) => {
 
        if (this.checkErrors()) {
            return false;
        }

        this.setState({ loading: true });
        const { projectMain, projectSecondary, encargado, productsICA, productsAP, company } = this.state;
        const { history } = this.props;
        const linesICA = productsICA.map((e) => ({
            product_id: e.id,
            cant_pedido: e.cant,
            warehouse_id: e.warehouse.value,
            priority: e.priority.value,
        }));
        const linesAP = productsAP.map((e) => ({
            product_id: e.id,
            cant_pedido: e.cant,
            warehouse_id: e.warehouse.value,
            priority: e.priority.value,
        }));

        const engproject = { engineer_id: encargado.value,
             company_id: company.value, 
             main_project: projectMain.value,
             products_ap : linesAP,
             products_ica: linesICA,
            };

        if (projectSecondary) {
            engproject.secondary_project = projectSecondary.id;
        }

        return CreateEngproject(engproject)
            .then((proj) => {
                const engprojectCodes = [{ id: projectMain.value, status: "used" }];
                if (projectSecondary) {
                    engprojectCodes.push({ id: projectSecondary.id, status: "used" });
                }
                return UpdateItems("engprojectscodes", engprojectCodes)
                    .then(() => {
                        toast.success("¡Proyecto creado con Exito!");
                        if (action === "GG") {
                            return history.push(`/proyectos-de-ingeneria/${proj.id}`);
                        }
                        if (action === "GR") {
                            return history.push("/proyectos-de-ingeneria/listado");
                        }
                        return this.setState({
                            projectMain: null,
                            projectSecondary: null,
                            encargado: null,
                            productsICA: [],
                            productsAP: [],
                            company: null,
                        });
                    })
                    .catch((e) => {
                        toast.info(`¡Error!, ${e.message}`);
                    });
            })
            .catch((e) => {
                toast.error(`¡Error!, ${e.message}`);
            })
            .finally(() => this.setState({ loading: false }));
    };

    ChangeProject = (projectMain) => {
        if (projectMain) {
            const { company } = this.state;
            return getProjectByCode(`${projectMain.code}-${company.code === "ICA" ? "AP" : "ICA"}`).then((projectSecondary) =>
                this.setState({ projectMain, projectSecondary, productsICA: [], productsAP: [] })
            );
        }
    };

    toggle = (isOpen) => this.setState({ isOpen: !isOpen });

    changeProducts = (table, products) => {
        if (table === "ICA") {
            return this.setState({ productsICA: [...products] });
        }
        return this.setState({ productsAP: [...products] });
    };

    render() {
        const {
            errors,
            isOpen,
            projectMain,
            projectSecondary,
            encargado,
            loading,
            company,
            companys,
            projects,
            whsICA,
            whsAP,
            productsICA,
            productsAP,
        } = this.state;
        const breadcrumb = [
            { title: "Home", url: "/" },
            { title: "Proyectos de Ingeneria", url: "/proyectos-de-ingeneria" },
            { title: "Nuevo Proyecto", url: "" },
        ];

        return (
            <React.Fragment>
                <PageHeader breadcrumb={breadcrumb} />
                <div className="container">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="mb-3">Inicializar Proyecto</h3>
                            <div className="row">
                                <Select
                                    options={companys}
                                    value={company}
                                    onChange={this.getProjectsPending}
                                    className="col-md-6 mt-2"
                                    placeholder="Seleccione Compañia Encargada..."
                                />
                                <div className="col-md-6 mt-2">
                                    <AsyncSelect
                                        isClearable
                                        cacheOptions
                                        loadOptions={this.promiseOptions2}
                                        noOptionsMessage={() => "No se Encontró ningun ingeniero."}
                                        loadingMessage={() => "Buscando..."}
                                        placeholder="Escriba en nombre del Ingeniero Encargardo..."
                                        onChange={(e) => this.setState({ encargado: e })}
                                        value={encargado}
                                    />
                                    {errors && errors.encargado && <span className="text-danger mt-1 ml-2">{errors.encargado}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="card-divider" />
                        <div className="card-body">
                            <div className="row">
                                {company && (
                                    <React.Fragment>
                                        <div className="col-md-6">
                                            <Select
                                                options={projects}
                                                value={!company ? null : projectMain}
                                                onChange={this.ChangeProject}
                                                placeholder={`Buscar proyecto ${company.code}...`}
                                                loadingMessage={() => "Buscando..."}
                                                isDisabled={!company}
                                            />
                                            {errors && errors.main_project && <span className="text-danger mt-1 ml-2">{errors.main_project}</span>}
                                            {errors && errors.orden_venta && <span className="text-danger mt-1 ml-2">{errors.orden_venta}</span>}
                                            <div>
                                                {projectMain && (
                                                    <table width="100%" className="table table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th width="40%">Cliente</th>
                                                                <td width="60%" align="right">
                                                                    {projectMain.name_cliente}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th width="40%">Orden de Venta</th>
                                                                <td width="60%" align="right">
                                                                    {!projectMain.orden_venta ? (
                                                                        <span className="badge badge-danger">Proyecto sin Orden de Venta</span>
                                                                    ) : (
                                                                        projectMain.orden_venta
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th width="40%">Fecha de Entrega</th>
                                                                <td width="60%" align="right">
                                                                    {moment(projectMain.fecha_entrega).format("DD/MM/YYYY")}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th width="40%">Compañia</th>
                                                                <td width="60%" align="right">
                                                                    {projectMain.company_id.code}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            {projectMain && (
                                                <table width="100%" className="table table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <th width="40%">{`Código de Proyecto en ${company.code !== "ICA" ? "ICA" : "AP"}`}</th>
                                                            <td width="60%" align="right">
                                                                {projectSecondary
                                                                    ? projectSecondary.code
                                                                    : `${projectMain.code}-${company.code === "ICA" ? "AP" : "ICA"}`}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th width="40%">Cliente</th>
                                                            <td width="60%" align="right">
                                                                {projectSecondary ? (
                                                                    projectSecondary.name_cliente
                                                                ) : (
                                                                    <span className="badge badge-danger">{`Este proyecto no ha sido creado en SAP de ${
                                                                        company.code === "ICA" ? "AP" : "ICA"
                                                                    }`}</span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th width="40%">Orden de Venta</th>
                                                            <td width="60%" align="right">
                                                                {!projectSecondary || (projectSecondary && !projectSecondary.orden_venta) ? (
                                                                    <span className="badge badge-danger">Proyecto sin Orden de Venta</span>
                                                                ) : (
                                                                    projectSecondary.orden_venta
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th width="40%">Compañia</th>
                                                            <td width="60%" align="right">
                                                                {projectSecondary && projectSecondary.company_id.code}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </React.Fragment>
                                )}
                                <div className="col-12">
                                    {projectMain && projectMain.orden_venta && (
                                        <ProductsTable
                                            errors={company.code === "ICA" ? errors && errors.productsICA : errors && errors.productsAP}
                                            products={company.code === "ICA" ? productsICA : productsAP}
                                            changeProducts={this.changeProducts}
                                            table={company.code}
                                            whs={company.code === "ICA" ? whsICA : whsAP}
                                        />
                                    )}
                                    {projectSecondary && projectSecondary.orden_venta && (
                                        <ProductsTable
                                            errors={company.code !== "ICA" ? errors && errors.productsICA : errors && errors.productsAP}
                                            products={company.code !== "ICA" ? productsICA : productsAP}
                                            changeProducts={this.changeProducts}
                                            table={company.code === "ICA" ? "AP" : "ICA"}
                                            whs={company.code === "ICA" ? whsAP : whsICA}
                                        />
                                    )}

                                    {projectMain && (
                                        <ButtonDropdown isOpen={isOpen} toggle={() => this.toggle(isOpen)}>
                                            <Button size="lg" color="primary" id="caret" onClick={() => this.save("GG")}>
                                                <i className={`${loading ? "fas fa-cog fa-spin" : "fas fa-save"} mr-2`} />
                                                Guardar y Gestionar
                                            </Button>
                                            <DropdownToggle split size="lg" color="primary" />
                                            <DropdownMenu>
                                                <DropdownItem header>Otras Acciones</DropdownItem>
                                                <DropdownItem onClick={() => this.save("GR")}>
                                                    <i className={`${loading ? "fas fa-cog fa-spin" : "fas fa-save"} mr-2`} />
                                                    Guardar y volver a listado
                                                </DropdownItem>
                                                <DropdownItem onClick={() => this.save("SG")}>
                                                    <i className={`${loading ? "fas fa-cog fa-spin" : "fas fa-save"} mr-2`} /> Solo Guardar
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
