
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import ReceiveMaterialsModal from '../ReceiveMaterialsModal'
import { statusBadge } from '../../utils';
import { statusProgress, statusProgressLavel } from '../../../cus_engprojects/utils';


export default function ProjectsList({ project, internalNode, table, currentUser, update }) {
  const [expandedRows, setExpandedRows] = useState(null);
  const [open, setOpen] = useState(false)
  const [openReturn, setOpenReturn] = useState(false)

  const rowExpansionTemplate = (row) => {
    const line = project.projects.find((e) => e.id === row.id);

    return (
      <div className="orders-subtable">
        {internalNode && internalNode(line)}
      </div>
    )
  }

  const header = project.status !== 'finished' ?
    (<React.Fragment>
      <Button color="secondary" id="toggler" style={{ marginBottom: '1rem' }} onClick={() => setOpen(true)}>
        <i class="fas fa-truck mr-1" />Recibir
      </Button>
      <Button className='ml-2' color="secondary" id="toggler" style={{ marginBottom: '1rem' }} onClick={() => setOpenReturn(true)}>
        <i class="fas fa-undo-alt mr-1" />Devolver
      </Button>
    </React.Fragment>) :
    <></>;

  const generateItems = () => {
    return project && project.projects.map((e) => ({
      status: e.status,
      id: e.id,
      code: e.mainProject.code,
      name: e.mainProject.name,
      dateCreated: e.dateCreated,
      dateFinished: e.dateFinish,
      test: project.test,
      toDeliver: project.todeliver,
      p1: project.p1,
      p2: project.p2,
      p3: project.p3,
      p4: project.p4
    }))
  }

  return (
    <div>
      <div className="datatable-rowexpansion-demo">
        <DataTable
          value={generateItems()}
          dataKey="id"
          header={header}
          selectionMode="checkbox"
          expandedRows={expandedRows}
          rowExpansionTemplate={rowExpansionTemplate}
          onRowToggle={(e) => setExpandedRows(e.data)}
          paginator
          rows={10}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Viendo {first} a {last} de {totalRecords}"
          rowsPerPageOptions={[10, 20, 50, 100]}
          removableSort
          emptyMessage="No se han encontrado registros..."
          className="p-datatable-customers"
        >
          <Column expander style={{ width: '4%' }} />
          <Column
            field="code"
            header="Código"
            style={{ width: '10%', textAlign: 'left' }}
            sortable
          />
          <Column
            field="name"
            header="Nombre"
            style={{ width: '10%', textAlign: 'left' }}
            sortable
          />
          <Column
            field="dateCreated"
            header="Fecha de Creación"
            style={{ width: '10%', textAlign: 'left' }}
            sortable
          />
          <Column
            field="dateFinished"
            header="Fecha de Finalización"
            style={{ width: '10%', textAlign: 'left' }}
            sortable
          />
          <Column
            field="status"
            header="Estado Cliente"
            style={{ width: '10%', textAlign: 'left' }}
            sortable
            body={(rowData) => {
              return statusProgress(rowData.status, (project.p1 || project.p2 || project.p3 || project.p4), project.test, project.todeliver)
            }}

          />
        </DataTable>
      </div>
      {open && <ReceiveMaterialsModal
        open={open}
        setOpen={setOpen}
        project={project}
        table={table}
        currentUser={currentUser}
        update={update}
        deliver={true}
      />}
      {openReturn && <ReceiveMaterialsModal
        open={openReturn}
        setOpen={setOpenReturn}
        project={project}
        table={table}
        currentUser={currentUser}
        update={update}
        deliver={false}
      />}
    </div>
  )
}

