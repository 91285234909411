export const generateOptionsSeries = (project) => {
    const { projects } = project

    if (projects) {
        const setSerials = new Set();

        projects.forEach((pry) => {
            const [first, second] = pry.mainProject.code.split('-');

            setSerials.add(`${first}-${second}`)
        })

        const options = [{ label: 'Todas', value: 'ALL' }]

        setSerials.forEach((e) => {
            options.push({ label: e, value: e },)
        })

        return options;
    }
    return null;

}

export const generateListForReseive = (project, table, deliver) => {
    if (deliver) {
        return project.projects.filter((prj) => prj.status !== 'finished').reduce((acc, current) => {
            const products = table === 'ICA' ? current.productsICA : current.productsAP;

            products.forEach((p) => {
                p.movements.forEach((movement) => {
                    if (movement.type === "delivery" && movement.confirmed_qty !== movement.delivered_qty) {
                        acc.push({
                            ...movement, product: {
                                id: p.id,
                                code: p.code
                            }, project: {
                                code: current.mainProject.code,
                                name: current.mainProject.name
                            }
                        });
                    }
                }, [])
            })

            return acc;
        }, []);
    }
    return project.projects.filter((prj) => prj.status !== 'finished').reduce((acc, current) => {
        const products = table === 'ICA' ? current.productsICA : current.productsAP;

        products.forEach((p) => {
            if (p.movements.length) {
                const confirmed_qty = p.movements.reduce((acc, current) => {
                    if (current.type === "delivery") {
                        acc += current.confirmed_qty ?? 0;
                    }

                    return acc;
                }, 0)

                if (confirmed_qty > 0) {
                    acc.push({
                        delivered_qty: confirmed_qty,
                        product: {
                            id: p.id,
                            code: p.code,
                            warehouse: p.warehouse_id.label

                        }, project: {
                            code: current.mainProject.code,
                            name: current.mainProject.name
                        }
                    })
                }

            }

        })

        return acc;
    }, []);
}

